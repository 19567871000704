import React, { Component } from 'react';
import styled from 'styled-components';

import callApi from '../../services/api';
import Feedback from './v3/Feedback';
import { Title1 } from './v3/styledComponents';

const FeedbacksContainer = styled.div`
  display: flex;
  margin-top: 91px;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-top: 40px;
  }
`;

class CustomerFeedbacks extends Component {
  constructor() {
    super();
    this.state = {
      customerFeedbacks: [],
    };
    this.setCustomerFeedbacks = this.setCustomerFeedbacks.bind(this);
  }

  componentDidMount() {
    callApi('/customerFeedbacks', 'GET')
      .then(({ customerFeedbacks }) => this.setCustomerFeedbacks(customerFeedbacks));
  }

  setCustomerFeedbacks(customerFeedbacks) {
    this.setState({ customerFeedbacks });
  }

  render() {
    const { customerFeedbacks } = this.state;
    return (
      <div>
        <Title1>Avis de nos clients</Title1>
        <FeedbacksContainer>
          {customerFeedbacks.map(({
            customerFirstname, couturierFirstname, customerFeedbackComment, customerFeedbackRating,
            customerFeedbackDate,
          }) => (
            <Feedback
              key={`${customerFirstname}_${customerFeedbackDate}`}
              isOpened
              feedback={{
                name: customerFirstname,
                feedback: customerFeedbackComment,
                rating: customerFeedbackRating / 2,
                jobDescription: `#MyTilliste ${couturierFirstname} `,
              }}
              isTransparent
              isSmall
            />
          ))}
        </FeedbacksContainer>
      </div>
    );
  }
}

export default CustomerFeedbacks;
